import React, { useEffect, useState } from "react";
import AccountMain from "./../../../styles/layouts/Account/components/Main.module.css";
import CustomTooltip from "../../../utils/CustomTooltip";
import ConfirmDialog from "./ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface BookingDTO {
  id: number;
  bookingDate: string;
  bookingStatus: string;
  confirmed: boolean;
  message: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  street: string;
  buildingNumber: string;
  flatNumber: string;
}

const BookingsToConfirm: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState<{ [key: number]: boolean }>({});

  const [bookings, setBookings] = useState<BookingDTO[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<number | null>(
    null
  );
  const [filter, setFilter] = useState('all');

  const filteredBookings = bookings.filter((booking) => {
    const now = new Date(); // bieżąca data i czas
    const bookingDate = new Date(booking.bookingDate); // data rezerwacji

    if (filter === 'confirmed') {
      return booking.confirmed && bookingDate > now;
    } else if (filter === 'unconfirmed') {
      return !booking.confirmed;
    } else if (filter === 'past') {
      return bookingDate < now; // sprawdź, czy data rezerwacji jest wcześniejsza niż bieżąca data
    }
    return true; // dla 'all' nie filtruj niczego
  });

  const fetchBookings = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${apiUrl}/v1/booking/getBookings`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      data.sort((a: BookingDTO, b: BookingDTO) =>
        new Date(a.bookingDate).getTime() - new Date(b.bookingDate).getTime()
      );
      setBookings(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };


  const cancelBooking = async (bookingId: number, initiator: 'USER_ROLE' | 'ADMIN_ROLE') => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${apiUrl}/v1/booking/cancel/${bookingId}/${initiator}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setBookings((currentBookings) =>
          currentBookings.filter((booking) => booking.id !== bookingId)
        );
        // console.log("Booking cancelled successfully");
      } else {
        // console.error("Failed to cancel booking");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const confirmBooking = async (
    bookingId: number,
    ignoreConflicts: boolean = false
  ) => {
    const token = localStorage.getItem("token");

    let url = `${apiUrl}/v1/booking/confirmBooking/${bookingId}`;

    if (ignoreConflicts) {
      url += "?force=true";
    }

    try {
      setIsLoading((prev) => ({ ...prev, [bookingId]: true }));
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("Potwierdzenie rezerwacji: ", data);
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.id === bookingId ? { ...booking, confirmed: true } : booking
          )
        );
      } else if (data.error && data.error.includes("Masz już zaplanowane wydarzenie w kalendarzu w tym czasie, czy chcesz potweirdzić mimo to?")) {
        if (window.confirm(data.error)) {
          confirmBooking(bookingId, true);
        }
      } else {
        console.error("Błąd przy potwierdzaniu rezerwacji: ", data);
      }
    } catch (error) {
      console.error("Błąd: ", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [bookingId]: false }));
    }
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    return `${date.toLocaleDateString(
      "pl-GB",
      optionsDate
    )} ${date.toLocaleTimeString("pl-GB", optionsTime)}`;
  };

  const truncateMessage = (message: string, maxLength: number = 30) => {
    if (message.length > maxLength) {
      return `${message.substring(0, maxLength)}...`;
    }
    return message;
  };

  const promptCancelBooking = (bookingId: number | null) => {
    setSelectedBookingId(bookingId);
    setIsDialogOpen(true);
  };

  const promptConfirmBooking = (bookingId: number) => {
    // console.log('Trying to confirm booking with ID:', bookingId);
    confirmBooking(bookingId);
  };

  const handleCancelBooking = () => {
    if (selectedBookingId !== null) {
      cancelBooking(selectedBookingId, "ADMIN_ROLE");
      setIsDialogOpen(false);
      setBookings((currentBookings) =>
        currentBookings.filter((booking) => booking.id !== selectedBookingId)
      );
    }
  };

  const handleCancelDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    // console.log("Bookings: ", bookings);
    fetchBookings();
  }, []);

  return (
    <>
      {bookings.length > 0 ? (
        <>
          <div>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className={AccountMain.filterSelect}
            >
              <option value="all">Wszystkie</option>
              <option value="confirmed">Potwierdzone</option>
              <option value="unconfirmed">Do potwierdzenia</option>
              <option value="past">Odbyte</option>
            </select>
          </div>
          <div className={AccountMain.nextReservation}>
            <table className={AccountMain.appointmentTable}>
              <thead>
                <tr>
                  <td>Data rezerwacji</td>
                  <td>Potwierdzona</td>
                  <td>Imię i Nazwisko</td>
                  <td>Numer telefonu</td>
                  <td>Adres</td>
                  <td>Wiadomość</td>
                  <td>Akcje</td>
                </tr>
              </thead>
              <tbody>
                {filteredBookings.map((booking) => (
                  <tr key={booking.id}>
                    <td>{formatDate(booking.bookingDate)}</td>
                    <td>
                      {booking.confirmed ? "Potwierdzono" : "Nie Potwierdzono"}
                    </td>
                    <td>
                      {booking.firstName} {booking.lastName}
                    </td>
                    <td>{booking.phoneNumber}</td>
                    <td>
                      {booking.city} {booking.street} {booking.buildingNumber}/
                      {booking.flatNumber}
                    </td>
                    <td>
                      <CustomTooltip text={booking.message}>
                        {truncateMessage(booking.message)}
                      </CustomTooltip>
                    </td>
                    <td>
                      {booking.confirmed ? ""
                        :
                        <button
                          className={AccountMain.confirmBtn}
                          onClick={() => promptConfirmBooking(booking.id)}
                        >
                          {isLoading[booking.id] ? <FontAwesomeIcon icon={faSpinner} spin /> : "Potwierdź"}
                        </button>

                      }

                      <button onClick={() => promptCancelBooking(booking.id)}>
                        Odwołaj
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ConfirmDialog
              isOpen={isDialogOpen}
              message="Czy na pewno chcesz anulować wizytę?"
              onConfirm={handleCancelBooking}
              onCancel={handleCancelDialog}
            />
          </div>
        </>
      ) : (
        <div className={AccountMain.nextReservation}>
          <p>Nie masz przychodzących rezerwacji.</p>
        </div>
      )}
    </>
  );
};

export default BookingsToConfirm;
